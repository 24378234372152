.dot {
    font-size: '1.5rem';
}

.dot1 {
    animation: visibility 1.3s linear infinite;
}
  
@keyframes visibility {
    0% {
        opacity: 1;
    }
    65% {
        opacity: 1;
    }
    66% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
  
.dot2 {
    animation: visibility2 1.3s linear infinite;
}
  
@keyframes visibility2 {
    0% {
        opacity: 0;
    }
    21% {
        opacity: 0.5;
    }
    22% {
        opacity: 1;
    }
    65% {
        opacity: 1;
    }
    66% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
  
.dot3 {
    animation: visibility3 1.3s linear infinite;
}
  
@keyframes visibility3 {
    0% {
        opacity: 0;
    }
    43% {
        opacity: 0.5;
    }
    44% {
        opacity: 1;
    }
    65% {
        opacity: 1;
    }
    66% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}
  